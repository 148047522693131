.four-digit-input {
  font-family: "Roboto Mono", monospace;
  font-size: 36px;
  color: #23205f;
  letter-spacing: 29px;
  width: 230px;
  padding-left: 20px;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
.four-digit-indicator {
  width: 230px;
  display: flex;
  justify-content: space-between;
  padding: 0 29px 0 16px;
  margin-top: 13px;

  span {
    width: 30px;
    height: 4px;
    background-color: #e9e8ef;
  }
}
.four-digit-indicator.one {
  span:nth-child(1) {
    background-color: #23205f;
  }
}
.four-digit-indicator.two {
  span:nth-child(1),
  span:nth-child(2) {
    background-color: #23205f;
  }
}
.four-digit-indicator.three {
  span:nth-child(1),
  span:nth-child(2),
  span:nth-child(3) {
    background-color: #23205f;
  }
}
.four-digit-indicator.four {
  span:nth-child(1),
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4) {
    background-color: #23205f;
  }
}
