.sign-up-form-header {
  background-color: #f1f1f2;

  .sign-in-with-social-buttons {
    padding-top: 22px;
    padding-bottom: 24px;
  }
}
.radio-input-wrapper {
  font-family: "Roboto", sans-serif;
  color: #23205f;
  margin-top: 0;
  margin-bottom: 8px;
  position: relative;

  p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 34px;
  }
  .option-wrapper {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-right: 31px;
    margin-left: 16px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #23205f;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      border: 2px solid #d2d2d2;
      transition: border 250ms;
    }
  }

  /* On mouse-over, add a grey background color */
  .option-wrapper:hover input ~ .checkmark {
    border: 2px solid #b91d23;
    transition: border 100ms;
  }

  /* When the radio button is checked, add a blue background */
  .option-wrapper input:checked ~ .checkmark {
    // background-color: #2196f3;
    border: 2px solid #b91d23;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    transform: scale(0);
    transition: transform 150ms;
  }

  /* Show the indicator (dot/circle) when checked */
  .option-wrapper input:checked ~ .checkmark:after {
    transform: scale(1);
  }

  /* Style the indicator (dot/circle) */
  .option-wrapper .checkmark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #b91d23;
  }

  .error {
    position: absolute;
    margin-left: 16px;
    bottom: -10px;
    display: block;
    color: #b91d23;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    // animation: errorFadeIn 300ms;

    @keyframes errorFadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 100%;
      }
    }
  }
}

.sign-up-step-1 {
  padding-top: 24px;
  padding-bottom: 80px;

  .intro-input-wrapper {
    margin-top: 32px;
  }
  button.red {
    margin-top: 32px;
  }
  .already-have {
    font-size: 18px;
    color: #23205f;
    margin-top: 35px;
    display: block;
    text-align: center;

    a {
      font-size: 16px;
      color: #5e8aa8;
      font-weight: 500;

      &:hover {
        color: lighten(#b91d23, 13%);
      }
    }
  }
}
.accept{
  margin-top: 32px;
  align-items: center;
  position: relative;
  a {
    font-size: 16px;
    color: #5e8aa8;
    font-weight: 500;

    &:hover {
      color: lighten(#b91d23, 13%);
    }
  }
  .error {
    position: absolute;
    margin-left: 16px;
    bottom: -15px;
    display: block;
    font-weight: normal;
    color: #b91d23;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    // animation: errorFadeIn 300ms;

    @keyframes errorFadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 100%;
      }
    }
  }
}
