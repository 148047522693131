.sign-up-screen-header {
  min-height: 128px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
  h1:only-child {
    margin-bottom: 5px;
  }

  .steps-counter {
    margin-top: 10px;
  }
}
