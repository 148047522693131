.button {
  height: 48px;
  border-radius: 4px;
  background: none;
  border: 0;
  padding: 10px 24px 12px;
  min-width: 112px;
  font-size: 20px;
  font-weight: 600;
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 1020px) {
    font-size: 14px;
    padding: 8px 16px 8px;
    min-width: 64px;
    height: 40px;
  }

  .link-inside-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    transition-duration: 150ms;
  }

  &.secondary {
    border: solid 2px #dedee8;
    color: #23205f;

    &:hover,
    &:focus {
      background-color: lighten(#23205f, 63%);
      border-color: lighten(#23205f, 63%);
    }
  }
  &.primary {
    background-color: #23205f;
    color: #ffffff;

    &:hover,
    &:focus {
      box-shadow: 0 7px 7px #23205f33;
      background-color: lighten(#23205f, 20%);
    }
  }
  &.red {
    color: #ffffff;
    background-color: #b91d23;

    &:disabled,
    &[disabled] {
      background-color: #d2d2d2;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      box-shadow: 0 7px 7px #b91d2355;
      background-color: lighten(#b91d23, 13%);
    }

    &.fullwidth {
      width: 100%;

      &:disabled,
      &[disabled] {
        background-color: #d2d2d2;
        pointer-events: none;
      }
    }

    &.search {
      height: 56px;
    }
  }
  &.gray {
    color: #23205f;
    background-color: #e9e8ef;

    &:disabled,
    &[disabled] {
      background-color: #d2d2d2;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      box-shadow: 0 7px 7px #e9e8ef55;
      background-color: lighten(#e9e8ef, 5%);
    }
    
    &.fullwidth {
      width: 100%;

      &:disabled,
      &[disabled] {
        background-color: #d2d2d2;
        pointer-events: none;
      }
    }

    &.search {
      height: 56px;
    }
  }
  &.tag {
    height: 40px;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: #e9e8ef;
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;

    &:hover,
    &:focus {
      color: #23205f;
      background-color: lighten(#23205f, 63%);
    }
  }

  &.like {
    height: 40px;
    padding: 11px 16px;
    font-size: 16px;
    color: #ffffff;
    min-width: unset;
    background-color: #b91d23;

    img {
      margin-bottom: -2px;
    }

    &:disabled,
    &[disabled] {
      background-color: #d2d2d2;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      box-shadow: 0 7px 7px #b91d2355;
      background-color: lighten(#b91d23, 13%);
    }

    &.liked {
      background-color: #d2d2d2;

      &:hover {
        box-shadow: none;
      }
    }
  }
}
