

.modal {
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  
  .modal-wrapper {
    background-color: #fefefe;
    margin: auto;
    padding: 8px;
    // padding: 13px 13px 20px 27px;
    border: 1px solid #888;
    width: 672px;
    min-height: 396px;
    border-radius: 4px;
    position: relative;

    .modal-inner {
      display: flex;
      flex-direction: column;
      padding: 24px 40px 40px 40px;

      h3 {
        font-size: 40px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: center;
        color: #23205f;
        margin-bottom: 32px;
        align-self: flex-start;
        margin-top: 0;
      }


      .modal-send-button {
        align-self: flex-end;
        font-size: 20px;
        line-height: 1.2;
        height: 48px;
      }
    }
    

    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      align-self: flex-end;
    }

    .close::before {
      content: '';
      width: 32px;
      height: 32px;
      background-image: url(../../assets/img/close-icon.svg);
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      position: absolute;
      top: 8px;
      right: 8px;
    }
    
    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
} 

@media screen and (max-width: 767px) {
  .modal {

    .modal-wrapper {
      width: 80vw;
    }
  }
}

@media screen and (max-width: 650px) {
  .modal {

    .raise-query-form {
      width: 100%;

      .modal-wrapper {
        width: 100%;
        border: none;
        border-radius: 0px;

        .modal-inner {
          padding: 48px 16px 30px 16px;

          h3 {
            margin-bottom: 16px;
          }
        }
        

        .close::before { 
          top: 16px;
          right: 16px;
        }
      }
    }
    
  }
}

@media screen and (max-width: 425px) {
  .modal {

    .raise-query-form {
      

      .modal-wrapper {
        
        .modal-inner {

          h3 {
            font-size: 28px;
            line-height: 1.43;
          }

          
        }
      }
    }
    
  }
}