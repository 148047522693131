.medium-sized-wrapper {
  width: 100%;
  max-width: 576px;
  margin: 0 auto;
}

.s-intro-form {
  padding: 0 20px;
}

.intro-screen-wrapper {
  padding-bottom: 80px;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

// .error {
//   color: #b91d23;
// }

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    flex-grow: 0;
  }

  .routes-wrapper {
    position: relative;
    flex-grow: 1;

    .one-route {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}
