.sign-in-with-social-buttons {
  border-top: 1px solid #e9e8ef;
  padding-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    color: #23205f;
  }
  .signin-with-social-buttons-right {
    display: flex;

    button {
      display: flex;
      align-items: center;
      margin-left: 68px;
      font-size: 22px;
      letter-spacing: -0.2px;
      text-decoration: none;
      border: none;
      background: transparent;
      cursor: pointer;

      img {
        margin-right: 13px;
      }

      &.facebook-sign-in-link {
        color: #23205f;
      }
      &.linkedin-sign-in-link {
        color: #242a3a;
      }
    }
    a:hover {
      color: lighten(#b91d23, 13%);
    }
  }
}
