.forgot-pass-screen-header {
  height: 128px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 40px;
  }
}
