.checkbox-wrapper {
  min-height: 24px;
  display: flex;
  align-items: flex-start;
  color: #939597;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  user-select: none;
  padding-right: 10px;

  input {
    display: none;
  }
  input:checked ~ .custom-checkbox {
    box-shadow: inset 0 0 0 9px #23205f;
    transition-delay: 0ms;

    .tick-left {
      transform: rotate(45deg);
      transition-delay: 150ms;
    }
    .tick-right {
      transform: rotate(-45deg);
      transition-delay: 220ms;
    }
  }
  .label {
    transition: color 200ms;
  }
  input:checked ~ span {
    color: #23205f;
  }

  .custom-checkbox {
    width: 18px;
    height: 18px;
    box-shadow: inset 0 0 0 2px #d1d3d4;
    border-radius: 2px;
    margin-right: 12px;
    transition: box-shadow 200ms ease;
    transition-delay: 190ms;
    position: relative;
    flex-shrink: 0;

    .tick-left {
      background-color: #ffffff;
      position: absolute;
      top: 9px;
      left: 4px;
      width: 5px;
      height: 2px;
      transform: rotate(45deg) translateX(-50%) scaleX(0);
      transition: transform 70ms;
      transition-delay: 120ms;
    }
    .tick-right {
      background-color: #ffffff;
      position: absolute;
      top: 8px;
      left: 6px;
      width: 10px;
      height: 2px;
      transform: rotate(-45deg) translateX(-50%) scaleX(0);
      transition: transform 120ms;
      transition-delay: 0ms;
    }
  }
}
