.small-card {
  display: flex;
  flex-direction: column;
  width: calc(33% - 12px);
  margin-top: 24px;
  text-decoration: none;
  color: unset;
  transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform, box-shadow;

  &:hover {
    box-shadow: 0 20px 30px -10px #000000aa;
    transform: translateY(-5px);
  }

  .top-text {
    background-color: #ffffff;
    padding: 22px 24px 24px 24px;

    .sub-heading {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #5e8aa8;
      margin-top: 0;
    }

    .heading {
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      font-weight: bold;
      margin-top: 7px;
      margin-bottom: 0;
    }

    .text {
      color: #414042;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 1.5;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .photo {
    height: 200px;
    width: 100%;
    object-fit: cover;
    align-self: stretch;
  }
}
