.s-tag-bar {
  overflow-x: scroll;

  .tag-bar {
    display: flex;
    padding: 16px 24px 12px;

    button {
      flex-shrink: 0;
      margin-left: 16px;
    }
    button:first-child {
      margin-left: 0;
    }
    .spacer {
      flex-basis: 24px;
      flex-shrink: 0;
    }
  }

  &.after-post {
    overflow: auto;
    flex-wrap: wrap;

    .tag-bar {
      padding-left: 0;

      button {
        margin-bottom: 10px;
      }
    }
  }
}
