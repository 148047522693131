.forgot-password-form-step-2 {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;

  button {
    margin-top: 32px;
  }
  span {
    max-width: 290px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #939597;
  }
  .email {
    margin-top: 16px;
    color: #404040;
    font-family: "Roboto", sans-serif;
    font-size: 24px;

    a {
      color: #404040;
      text-decoration: none;
      box-shadow: 0 2px 0 #4040402c;
    }
    a:hover {
      box-shadow: 0 2px 0 #b91d232c;
      color: lighten(#b91d23, 13%);
    }
  }
  a {
    margin-top: 16px;
    color: #5e8aa8;
  }
  a:hover {
    color: lighten(#b91d23, 13%);
  }
  .four-digit-input-wrapper {
    margin-top: 24px;
    margin-left: 14px;
  }

  .send-again {
    margin-top: 45px;
    button{
        margin-top: 16px;
        color: #5e8aa8;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    button:hover {
      box-shadow: 0 2px 0 #b91d232c;
      color: lighten(#b91d23, 13%);
    }

  }
}
