.s-base-search {
  width: 100%;
  padding: 0 20px;
  min-height: 266px;
  background-image: url("../../../assets/img/base-search-bg.jpg");
  background-size: cover;
  background-position: center;

  .search-wrapper {
    max-width: 746px;
    height: 266px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin-top: 0;
      margin-bottom: 32px;
      color: white;
      font-size: 40px;
    }
    .search-form-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 20px;
      font-family: "Roboto", sans-serif;

      input {
        height: 56px;
        border-radius: 4px;
        border: none;
        width: unset;
        min-width: 0;
        flex-grow: 6;
        padding: 0 20px;

        &:placeholder {
          color: #989898;
        }
      }
      button {
        margin-left: 24px;
        flex-grow: 1;
      }
    }
  }
}
