.sign-up-avatar {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/svg/avatar-placeholder.svg), #d2d2d2;
    background-position: center;
    background-repeat: no-repeat;
  }
  .uploaded-avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .upload-progressbar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    mix-blend-mode: multiply;
    transform: scale(1.02);
  }
}

.sign-up-avatar-uploader {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6px;
}

.avatar-upload-button {
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #23205f;
  padding: 10px 37px 12px;
  height: 40px;
  border-radius: 41px;
  border: solid 2px #23205f;
  line-height: 1;
  display: flex;
  margin-top: 31px;
  transition: all 300ms ease;

  svg {
    width: 15px;
    margin-right: 10px;

    .plus {
      fill: #23205f;
    }
  }

  &:hover {
    background-color: #23205f;
    color: white;
    box-shadow: 0 7px 7px #23205f33;

    svg .plus {
      fill: #ffffff;
    }
  }
}
