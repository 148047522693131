.s-big-card {
  display: flex;
  width: 100%;

  .photo {
    flex-basis: 48%;
    flex-shrink: 0;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      align-self: stretch;
    }
  }

  .right-column {
    background-color: #ffffff;
    padding: 37px 56px 44px 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .sub-heading {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #5e8aa8;
      margin-bottom: 0;
    }

    .heading {
      margin-top: 16px;
      font-family: "Roboto", sans-serif;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 0;
    }

    .text {
      color: #414042;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 1.75;
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }
}
