.author-card {
  display: flex;
  margin-top: 34px;
  justify-content: space-between;

  .left-wrapper {
    display: flex;

    .avatar {
      position: relative;
      width: 72px;
      height: 72px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
      .onlineness-dot {
        position: absolute;
        right: 3px;
        bottom: 3px;
        width: 14px;
        height: 14px;
        background-color: #4dd760;
        border-radius: 50%;

        border: 2px solid #ffffff;
      }
    }

    .info {
      margin-left: 24px;

      .name {
        font-family: TitilliumWeb, sans-serif;
        font-size: 24px;
        line-height: 1.33;
        color: #000000;
        margin-top: 7px;
        margin-bottom: 0;
      }
      .location {
        padding-left: 20px;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        margin-top: 4px;
        color: #a5a5b1;

        background-image: url(../../assets/svg/location-pin.svg);
        background-size: contain;
        background-position: left center;
        background-repeat: no-repeat;
      }
    }

    &.after-post {
      margin-top: 0;
      border-top: 2px solid #dadada;
      padding-top: 31px;
    }
  }

  .like-section {
    display: flex;
    align-items: center;

    span {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.5;
      color: #23205f;
    }
    .like {
      margin-left: 32px;
    }
  }
}
