.cover-pic {
  width: 100%;
  height: 32vh;
  object-fit: cover;
}
.s-post {
  padding: 16px 20px 49px;

  .post-wrapper {
    max-width: 745px;
    margin: 0 auto;
  }

  h1 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    color: #23205f;
    margin-top: 55px;
    margin-bottom: 0;
  }

  .intro-text {
    padding-bottom: 32px;
    border-bottom: 1px solid #e9e8ef;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.75;
    color: #414042;
  }
}

.s-more-from-base {
  background-color: #e9e8ef;
  padding: 24px;

  & > h2 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }

  .more-from-base-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
