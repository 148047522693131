.intro-input-wrapper {
  position: relative;
  font-family: "Roboto", sans-serif;

  .intro-input-label {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    left: 16px;
    top: 18px;
    color: #0000007e;
    transform-origin: top left;
    transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1), color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform;
    pointer-events: none;
  }
  .intro-input:focus ~ .intro-input-label,
  .has-text ~ .intro-input-label {
    transform: scale(0.7) translateY(-12px);
    font-weight: 500;
  }
  .intro-input:focus ~ .intro-input-label {
    color: #23205f;
  }

  .intro-input {
    width: 100%;
    height: 56px;
    background-color: #d1d3d44d;
    border: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #d1d3d4;
    padding: 27px 16px 10px 16px;
    font-size: 18px;
    position: relative;
    transition: background-color 200ms;

    &:hover {
      background-color: #d1d3d488;
    }

    &[type="date"] {
      -webkit-appearance: none;
      padding-right: 50px;
      padding-left: 13px;
    }
    &.select {
      -webkit-appearance: none;
      appearance: none;
      padding-left: 12px;
      padding-right: 50px;
      padding-bottom: 7px;
      overflow: hidden;
      text-overflow: ellipsis;

      option[value=""] {
        display: none;
      }
    }
  }
  .intro-input:focus {
    outline: none;
  }
  .intro-input-underline {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 54px;
    left: 0;
    background-color: #23205f;
    transform: scaleX(0);
    will-change: transform;
    transition: transform 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .intro-input:focus ~ .intro-input-underline {
    transform: scaleX(1);
  }
  .intro-input:not(:focus) ~ .pass-eye {
    display: none;
  }
  .intro-input:not(.has-text):not(:focus) {
    color: transparent;
  }

  .select-arrow {
    position: absolute;
    right: 22px;
    top: 26px;

    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #939597;
  }

  .helper {
    margin-left: 16px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 1.71;
  }
  .pass-eye {
    position: absolute;
    width: 45px;
    height: 45px;
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    background: url(../../assets/svg/pass-eye.svg);
    background-position: center;
    background-repeat: no-repeat;

    .eye-line {
      position: absolute;
      top: 21px;
      right: 9px;
      width: 25px;
      height: 2px;
      background-color: #8f8f8f;
      box-shadow: 0 2px 0 #f1f1f1;
      transform: rotate(45deg) translateX(0) scaleX(1);
      transition: transform 200ms ease-in-out;

      &.enabled {
        transform: rotate(45deg) translateX(-50%) scaleX(0);
      }
    }
  }

  .location-pin,
  .date-icon {
    position: absolute;
    width: 45px;
    height: 45px;
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  .location-pin {
    background-image: url(../../assets/svg/location-pin.svg);
  }

  .date-icon {
    background-image: url(../../assets/svg/date.svg);
  }

  .error {
    position: absolute;
    bottom: -17px;
    display: block;
    color: #b91d23;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    margin-left: 16px;
    // animation: errorFadeIn 300ms;

    @keyframes errorFadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 100%;
      }
    }
  }
}
