$thumb-width: 12px;
$thumb-height: 24px;
$track-w: 100%;
$track-h: 12px;
$track-c: #d1d3d44d;
$text-c: #23205f;
$fill-c: #23205f;
$ruler-c: #23205f;
$ruler-w: 2px;
$label-margin-bottom: 16px;
$label-line-height: 24px;
$numbers-top-margin: 20px;
$input-height: $thumb-height * 2 + $numbers-top-margin;

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  width: $track-w;
  height: $track-h;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    $fill-c calc(calc(var(--val, 3) - 1) / 4 * 100% - 0.5%),
    $track-c calc(calc(var(--val, 3) - 1) / 4 * 100% + 0.5%)
  );
}

@mixin fill() {
  height: $track-h;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: $fill-c;
}

@mixin thumb() {
  box-sizing: border-box;
  border: none;
  width: $thumb-width;
  height: $thumb-height;
  border-radius: 6px;
  background: $fill-c;
}

.intro-slider-wrapper {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  width: $track-w;
  color: $text-c;
  margin-top: 47px;
  font-family: "Roboto", sans-serif;

  [type="range"] {
    &,
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    margin: 0;
    padding: 0;
    width: $track-w;
    height: $input-height;
    padding-bottom: 20px;
    background: transparent;

    &::-webkit-slider-runnable-track {
      @include track(1);
    }
    &::-moz-range-track {
      @include track;
    }
    &::-ms-track {
      @include track;
    }

    &::-moz-range-progress {
      @include fill;
    }
    &::-ms-fill-lower {
      @include fill;
    }

    &::-webkit-slider-thumb {
      margin-top: 0.5 * ($track-h - $thumb-height);
      @include thumb;
    }
    &::-moz-range-thumb {
      @include thumb;
    }
    &::-ms-thumb {
      margin-top: 0;
      @include thumb;
    }

    &::-ms-tooltip {
      display: none;
    }
  }

  .text {
    font-size: 18px;
    line-height: $label-line-height;
    margin-bottom: $label-margin-bottom;
  }

  .labels {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    padding-left: 2px;
    padding-right: 1px;
  }
  .dots {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: $label-line-height + $label-margin-bottom + $input-height/2 - 13px;
    left: 0;
    padding-left: 3px;
    padding-right: 3px;
    z-index: -1;

    span {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #d1d3d4;
    }
  }
}
