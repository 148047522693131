@font-face {
  font-family: "Roboto-Regular";
  src: url(../../../assets/fonts/Roboto-Regular.ttf) format("truetype");
}


.sign-up-form-step-6 {
  width: 100%;
  

    .terms-and-conditions-main {
      background-color: #fafafa;
      width: 100%;
      margin-bottom: 24px;

      .terms-and-conditions-text-wrapper {
        padding-bottom: 65px;
        max-width: 672px;
        margin: 0 auto;
        padding-top: 72px;
        
        p {
          font-family: 'Roboto-Regular', Roboto, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          line-height: 1.5;
          color: #414042;
        }

        h3 {
          font-family: 'Roboto-Regular', Roboto, sans-serif;
          font-size: 24px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.67;
          letter-spacing: normal;
          color: #23205f;
          margin-top: 0;
          margin-bottom: 20px;
        }

        h3.h3-1 {
          margin-top: 0;
          margin-bottom: 12px;
        }
        p:nth-child(2) {
          margin-top: 0;
          margin-bottom: 0;
        }
        h3.h3-2 {
          margin-top: 40px;
        }
        h3.h3-4 {
          margin-top: 40px;
        }
        h3.h3-3 {
          margin-top: 16px;
        }
      }
    }
  .terms-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 672px;
    margin: 0 auto;

    a {
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #23205f;

      .download-link {
        display: flex;
        align-items: center;
        flex-direction: row;
  
        .icon-background {
          width: 40px;
          height: 40px;
          background-color: #23205f;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 24px;
  
          .icon {
            background-image: url(../../../assets/svg/download.svg);
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: inline-block;
            background-position: center;
          }
        }
      }
    }
    

    .button {
      font-weight: bold;
      line-height: 1.2;

      &.gray {
        width: 168px;
        margin-right: 23px;
      }

      &.red.toggle-modal {
        width: 168px;
      }
    }
  }

  .modal {

    .modal-wrapper {

      .modal-inner {

        .modal-content {
          position: relative;
          
          label {
            font-family: 'Roboto-Regular', Roboto;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #5e8aa8;
            position: absolute;
            top: 8px;
            left: 17px;
          }
          textarea {
            width: 100%;
            height: 154px;
            border-radius: 4px 4px 0 0;
            margin-bottom: 24px;
            border: none;
            border-bottom: solid 2px #5e8aa8;
            background-color: rgba(209, 211, 212, 0.3);
            max-width: 100%;
            resize: none;
            
            font-family: 'Roboto-Regular', Roboto;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #242a3a;

            border-top: 27px solid transparent;
            border-bottom: 17px solid transparent;
            border-right: 17px solid transparent;
            border-left: 17px solid transparent;
          }
        }
        .modal-send-button {
          width: 198px;
          min-width: 168px;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 22px;
          padding-right: 21px;  

        }
      } 
    }
  } 
}

@media screen and (max-width: 1020px) {
  .sign-up-form-step-6 {

    .link-text {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) {
  .large-sized-wrapper.intro-header-wrapper {
    text-align: center;
  }

  .sign-up-form-step-6 {
    .terms-and-conditions-main {

      .terms-and-conditions-text-wrapper {
        max-width: 85vw;
      }
    }

    .terms-buttons {
      max-width: 85vw;
    }
  }
}
@media screen and (max-width: 680px) {
  .sign-up-form-step-6 {
  
    .terms-buttons {
      a {
        margin-right: 22px;

        .download-link {
          .link-text {
            display: none;
          }
          .icon-background {
            margin-right: 0px;
          }      
        }
      }
      .accept-and-query {
        width: 100%;
        justify-content: flex-end;
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .sign-up-form-step-6 {
    .terms-buttons {
  
      .button {

        &.gray {
          width: 130px;
          padding: 0;
        }
        
        &.red.toggle-modal {
          width: 160px;
          padding: 0;
        }
      }
    } 
  }
}

@media screen and (max-width: 425px) {
  .large-sized-wrapper.intro-header-wrapper h1 {
    font-size: 18px;
    line-height: 1.17;
  }
  .sign-up-form-step-6 {

    .terms-and-conditions-main {
      margin-bottom: 32px;

      .terms-and-conditions-text-wrapper {
        padding: 32px 24px 24px 24px;
        max-width: 100%;

        h3 {
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          margin-bottom: 12px;
          margin-top: 32px;
        }

        p {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
        }

        h3.h3-1 {
          margin-top: 0;
        }
      }
    }
    
    .terms-buttons {
      max-width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      .accept-and-query {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;

        .button {

          &.gray {
            width: 100%;
            padding: 0;
          }
          
          &.red.toggle-modal {
            width: 145.2%;
            padding: 0;
          }
        }
      }

      
    } 
  }
}
