.sign-up-form-step-5 {
  padding-top: 32px;
  padding-bottom: 80px;

  .medium-sized-wrapper {
    padding: 0 20px;
    max-width: 576px + 20px + 20px;
  }
  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.33;
    color: #23205f;
    margin-bottom: -20px;
    margin-top: 32px;
  }

  button.red {
    margin-top: 32px;
  }

  .intro-input-wrapper {
    margin-top: 32px;
  }

  .checkboxes-section {
    padding: 32px 20px 15px;

    &.specialisms {
      margin-top: 41px;
      background-color: #d1d3d431;
    }
    &.skills {
      .skills-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 864px;
        margin: 0 auto;

        .skills-group {
          width: 33%;
          border-top: 1px solid #d1d3d4;
          margin-top: 32px;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            h4 {
              margin-top: 0;
            }
            margin-top: 0;
            border-top: none;
          }

          @media screen and (max-width: 678px) {
            width: 50%;
          }
          @media screen and (max-width: 440px) {
            width: 100%;
          }

          h4 {
            margin-top: 23px;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-weight: 500;
          }

          .checkboxes-group {
            flex-direction: column;

            .checkbox-wrapper {
              width: 100%;
            }
          }
        }
      }
    }

    h2 {
      font-size: 24px;
      max-width: 866px;
      margin: 0 auto;
      margin-bottom: 24px;
    }

    .checkboxes-group {
      max-width: 864px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;

      .checkbox-wrapper {
        width: 33%;
        margin-bottom: 17px;

        @media screen and (max-width: 678px) {
          width: 50%;
        }

        @media screen and (max-width: 440px) {
          width: 100%;
        }
      }
    }
  }
}
