.sign-up-form-step-4 {
  padding-top: 32px;
  padding-bottom: 80px;

  button.red {
    margin-top: 32px;
  }

  .intro-input-wrapper {
    margin-top: 32px;
  }
}
