.login-screen-header {
  height: 128px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 40px;
  }

  .login-screen-right {
    font-size: 18px;
    a {
      font-size: 16px;
      margin-left: 11px;
      color: #ffffff;
    }
    a:hover,
    a:focus {
      color: lighten(#b91d23, 13%);
    }
  }
}
