@import "../node_modules/normalize.css/normalize.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700|Titillium+Web:400,600,700&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
}

*::selection {
  background-color: #23205f;
  color: white;
}

body {
  font-family: "Titillium Web", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
