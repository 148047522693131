.sign-up-form-step-final {
  padding-top: 120px;
  padding-bottom: 80px;
  background-image: url("../../../assets/svg/x-for-complete.svg"), linear-gradient(to bottom, #23205f, #191919);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 40%, 100%;
  height: 100%;

  .medium-sized-wrapper {
    padding: 0 20px;
    max-width: 576px + 20px + 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;

    .tick-wrapper {
      width: 96px;
      height: 96px;
      position: relative;

      .tick-outer-left {
        position: absolute;
        top: 48px;
        left: 2px;
        width: 40px;
        height: 20px;
        background-color: #ffffff;
        transform: rotate(45deg) translateX(0) scaleX(1);
        animation: tickPartAnimationLeft 600ms 1000ms backwards ease-in;
      }
      .tick-outer-right {
        position: absolute;
        top: 40px;
        left: 17px;
        width: 85px;
        height: 20px;
        background-color: #ffffff;
        transform: rotate(315deg) translateX(0) scaleX(1);
        animation: tickPartAnimationRight 600ms 1570ms backwards ease-out;
      }

      .tick-inner-left {
        position: absolute;
        top: 58px;
        left: 6px;
        width: 39px;
        height: 7px;
        background-color: #201e50;
        transform: rotate(45deg) translateX(0) scaleX(1);
        animation: tickPartAnimationLeft 600ms 1000ms backwards ease-in;
      }
      .tick-inner-right {
        position: absolute;
        top: 45px;
        left: 28px;
        width: 66px;
        height: 7px;
        background-color: #201e50;
        transform: rotate(315deg) translateX(0) scaleX(1);
        animation: tickPartAnimationRight 600ms 1570ms backwards ease-out;
      }
    }

    h1 {
      margin-top: 24px;
      margin-bottom: 0;
      font-size: 40px;
      text-align: center;
    }
    p {
      text-align: center;
      margin-top: 23px;
      max-width: 250px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 1.5;
    }
    .button {
      margin-top: 41px;
      padding-left: 70px;
      padding-right: 70px;
    }
  }
}

@keyframes tickPartAnimationLeft {
  0% {
    transform: rotate(45deg) translateX(-50%) scaleX(0);
  }

  100% {
    transform: rotate(45deg) translateX(0) scaleX(1);
  }
}

@keyframes tickPartAnimationRight {
  0% {
    transform: rotate(315deg) translateX(-50%) scaleX(0);
  }

  100% {
    transform: rotate(315deg) translateX(0) scaleX(1);
  }
}
