.header-desktop-wrapper {
  padding: 0 24px 0 48px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1020px) {
    padding: 12px 16px;
  }

  .header-logo {
    margin: 21px 0;

    @media screen and (max-width: 1020px) {
      width: 104px;
      margin: 5px 0 4px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .header-menu-list {
    display: flex;
    margin: 0;

    @media screen and (max-width: 700px) {
      display: none;
      padding-left: 0;
    }
  }

  .header-menu-item {
    list-style: none;
  }

  .header-menu-link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #23205f;
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 96px;

    @media screen and (max-width: 1020px) {
      font-size: 14px;
      padding: 0 15px;
      height: 40px;
    }

    span {
      position: relative;
    }

    span:after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 3px;
      background-color: #b91d23;
      transform: scaleX(0);
      transform-origin: 0%;
      will-change: transform;
      transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
  .header-menu-link.active,
  .header-menu-link:hover,
  .header-menu-link:focus {
    span:after {
      transform: scaleX(1);
    }
  }

  .button.secondary {
    margin-left: 42px;

    @media screen and (max-width: 1020px) {
      margin-left: 16px;
    }
  }
  .button.primary {
    margin-left: 24px;

    @media screen and (max-width: 1020px) {
      margin-left: 16px;
    }
  }

  .user-link {
    display: flex;
    align-items: center;
    padding: 24px;
    color: #5e8aa8;
    font-family: "Roboto", sans-serif;
    font-size: 16px;

    &:hover {
      color: lighten(#b91d23, 13%);
    }

    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
      margin-left: 16px;
    }
  }
  .chat-messages-button {
    height: 100%;
    background-color: transparent;
    border: none;
    position: relative;
    padding-right: 12px;
    cursor: pointer;

    .chat-svg-path {
      fill: #23205f;
    }
    &:hover .chat-svg-path {
      fill: lighten(#b91d23, 13%);
    }

    .counter {
      display: block;
      position: absolute;
      bottom: 27px;
      right: 0;
      height: 20px;
      border-radius: 10px;
      background-color: #d8474d;
      color: white;
      padding: 1px 3px 0;
      min-width: 20px;
      text-align: center;
    }
  }
}
.header-mobile-wrapper {
  justify-content: center;
  display: none;
  border-top: 1px solid #e9e8ef;

  @media screen and (max-width: 700px) {
    display: flex;
  }

  .header-menu-list {
    display: flex;
    margin: 0;
    padding-left: 0;
  }

  .header-menu-item {
    list-style: none;
  }

  .header-menu-link {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: #23205f;
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 55px;

    span {
      position: relative;
    }

    span:after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 3px;
      background-color: #b91d23;
      transform: scaleX(0);
      transform-origin: 0%;
      will-change: transform;
      transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
  .header-menu-link.active,
  .header-menu-link:hover,
  .header-menu-link:focus {
    span:after {
      transform: scaleX(1);
    }
  }
}
