@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700|Titillium+Web:400,600,700&display=swap);
*,
*:before,
*:after {
  box-sizing: border-box; }

*::-moz-selection {
  background-color: #23205f;
  color: white; }

*::selection {
  background-color: #23205f;
  color: white; }

body {
  font-family: "Titillium Web", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh; }

.button {
  height: 48px;
  border-radius: 4px;
  background: none;
  border: 0;
  padding: 10px 24px 12px;
  min-width: 112px;
  font-size: 20px;
  font-weight: 600;
  -webkit-transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  position: relative; }
  @media screen and (max-width: 1020px) {
    .button {
      font-size: 14px;
      padding: 8px 16px 8px;
      min-width: 64px;
      height: 40px; } }
  .button .link-inside-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .button:hover {
    -webkit-transition-duration: 150ms;
            transition-duration: 150ms; }
  .button.secondary {
    border: solid 2px #dedee8;
    color: #23205f; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #d2d1ef;
      border-color: #d2d1ef; }
  .button.primary {
    background-color: #23205f;
    color: #ffffff; }
    .button.primary:hover, .button.primary:focus {
      box-shadow: 0 7px 7px #23205f33;
      background-color: #3f3aab; }
  .button.red {
    color: #ffffff;
    background-color: #b91d23; }
    .button.red:disabled, .button.red[disabled] {
      background-color: #d2d2d2;
      pointer-events: none; }
    .button.red:hover, .button.red:focus {
      box-shadow: 0 7px 7px #b91d2355;
      background-color: #e0383f; }
    .button.red.fullwidth {
      width: 100%; }
      .button.red.fullwidth:disabled, .button.red.fullwidth[disabled] {
        background-color: #d2d2d2;
        pointer-events: none; }
    .button.red.search {
      height: 56px; }
  .button.gray {
    color: #23205f;
    background-color: #e9e8ef; }
    .button.gray:disabled, .button.gray[disabled] {
      background-color: #d2d2d2;
      pointer-events: none; }
    .button.gray:hover, .button.gray:focus {
      box-shadow: 0 7px 7px #e9e8ef55;
      background-color: #f7f7f9; }
    .button.gray.fullwidth {
      width: 100%; }
      .button.gray.fullwidth:disabled, .button.gray.fullwidth[disabled] {
        background-color: #d2d2d2;
        pointer-events: none; }
    .button.gray.search {
      height: 56px; }
  .button.tag {
    height: 40px;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: #e9e8ef;
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif; }
    .button.tag:hover, .button.tag:focus {
      color: #23205f;
      background-color: #d2d1ef; }
  .button.like {
    height: 40px;
    padding: 11px 16px;
    font-size: 16px;
    color: #ffffff;
    min-width: unset;
    background-color: #b91d23; }
    .button.like img {
      margin-bottom: -2px; }
    .button.like:disabled, .button.like[disabled] {
      background-color: #d2d2d2;
      pointer-events: none; }
    .button.like:hover, .button.like:focus {
      box-shadow: 0 7px 7px #b91d2355;
      background-color: #e0383f; }
    .button.like.liked {
      background-color: #d2d2d2; }
      .button.like.liked:hover {
        box-shadow: none; }

.header-desktop-wrapper {
  padding: 0 24px 0 48px;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 1020px) {
    .header-desktop-wrapper {
      padding: 12px 16px; } }
  .header-desktop-wrapper .header-logo {
    margin: 21px 0; }
    @media screen and (max-width: 1020px) {
      .header-desktop-wrapper .header-logo {
        width: 104px;
        margin: 5px 0 4px; } }
  .header-desktop-wrapper .header-right {
    display: flex;
    align-items: center; }
  .header-desktop-wrapper .header-menu-list {
    display: flex;
    margin: 0; }
    @media screen and (max-width: 700px) {
      .header-desktop-wrapper .header-menu-list {
        display: none;
        padding-left: 0; } }
  .header-desktop-wrapper .header-menu-item {
    list-style: none; }
  .header-desktop-wrapper .header-menu-link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #23205f;
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 96px; }
    @media screen and (max-width: 1020px) {
      .header-desktop-wrapper .header-menu-link {
        font-size: 14px;
        padding: 0 15px;
        height: 40px; } }
    .header-desktop-wrapper .header-menu-link span {
      position: relative; }
    .header-desktop-wrapper .header-menu-link span:after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 3px;
      background-color: #b91d23;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0%;
              transform-origin: 0%;
      will-change: transform;
      -webkit-transition: -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .header-desktop-wrapper .header-menu-link.active span:after,
  .header-desktop-wrapper .header-menu-link:hover span:after,
  .header-desktop-wrapper .header-menu-link:focus span:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }
  .header-desktop-wrapper .button.secondary {
    margin-left: 42px; }
    @media screen and (max-width: 1020px) {
      .header-desktop-wrapper .button.secondary {
        margin-left: 16px; } }
  .header-desktop-wrapper .button.primary {
    margin-left: 24px; }
    @media screen and (max-width: 1020px) {
      .header-desktop-wrapper .button.primary {
        margin-left: 16px; } }
  .header-desktop-wrapper .user-link {
    display: flex;
    align-items: center;
    padding: 24px;
    color: #5e8aa8;
    font-family: "Roboto", sans-serif;
    font-size: 16px; }
    .header-desktop-wrapper .user-link:hover {
      color: #e0383f; }
    .header-desktop-wrapper .user-link img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
      margin-left: 16px; }
  .header-desktop-wrapper .chat-messages-button {
    height: 100%;
    background-color: transparent;
    border: none;
    position: relative;
    padding-right: 12px;
    cursor: pointer; }
    .header-desktop-wrapper .chat-messages-button .chat-svg-path {
      fill: #23205f; }
    .header-desktop-wrapper .chat-messages-button:hover .chat-svg-path {
      fill: #e0383f; }
    .header-desktop-wrapper .chat-messages-button .counter {
      display: block;
      position: absolute;
      bottom: 27px;
      right: 0;
      height: 20px;
      border-radius: 10px;
      background-color: #d8474d;
      color: white;
      padding: 1px 3px 0;
      min-width: 20px;
      text-align: center; }

.header-mobile-wrapper {
  justify-content: center;
  display: none;
  border-top: 1px solid #e9e8ef; }
  @media screen and (max-width: 700px) {
    .header-mobile-wrapper {
      display: flex; } }
  .header-mobile-wrapper .header-menu-list {
    display: flex;
    margin: 0;
    padding-left: 0; }
  .header-mobile-wrapper .header-menu-item {
    list-style: none; }
  .header-mobile-wrapper .header-menu-link {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: #23205f;
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 55px; }
    .header-mobile-wrapper .header-menu-link span {
      position: relative; }
    .header-mobile-wrapper .header-menu-link span:after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 3px;
      background-color: #b91d23;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0%;
              transform-origin: 0%;
      will-change: transform;
      -webkit-transition: -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .header-mobile-wrapper .header-menu-link.active span:after,
  .header-mobile-wrapper .header-menu-link:hover span:after,
  .header-mobile-wrapper .header-menu-link:focus span:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.s-intro-header {
  min-height: 128px;
  padding: 0 20px;
  background-image: url(/static/media/intro-screen-bg-icon.66a44b64.svg), -webkit-gradient(linear, left top, left bottom, from(#23205f), to(#191919));
  background-image: url(/static/media/intro-screen-bg-icon.66a44b64.svg), -webkit-linear-gradient(top, #23205f, #191919);
  background-image: url(/static/media/intro-screen-bg-icon.66a44b64.svg), linear-gradient(to bottom, #23205f, #191919);
  background-position: center top, center;
  background-repeat: no-repeat;
  background-size: 100% 100%; }

.intro-input-wrapper {
  position: relative;
  font-family: "Roboto", sans-serif; }
  .intro-input-wrapper .intro-input-label {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    left: 16px;
    top: 18px;
    color: #0000007e;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1), color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1), color 200ms cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform;
    pointer-events: none; }
  .intro-input-wrapper .intro-input:focus ~ .intro-input-label,
  .intro-input-wrapper .has-text ~ .intro-input-label {
    -webkit-transform: scale(0.7) translateY(-12px);
            transform: scale(0.7) translateY(-12px);
    font-weight: 500; }
  .intro-input-wrapper .intro-input:focus ~ .intro-input-label {
    color: #23205f; }
  .intro-input-wrapper .intro-input {
    width: 100%;
    height: 56px;
    background-color: #d1d3d44d;
    border: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #d1d3d4;
    padding: 27px 16px 10px 16px;
    font-size: 18px;
    position: relative;
    -webkit-transition: background-color 200ms;
    transition: background-color 200ms; }
    .intro-input-wrapper .intro-input:hover {
      background-color: #d1d3d488; }
    .intro-input-wrapper .intro-input[type="date"] {
      -webkit-appearance: none;
      padding-right: 50px;
      padding-left: 13px; }
    .intro-input-wrapper .intro-input.select {
      -webkit-appearance: none;
      -moz-appearance: none;
           appearance: none;
      padding-left: 12px;
      padding-right: 50px;
      padding-bottom: 7px;
      overflow: hidden;
      text-overflow: ellipsis; }
      .intro-input-wrapper .intro-input.select option[value=""] {
        display: none; }
  .intro-input-wrapper .intro-input:focus {
    outline: none; }
  .intro-input-wrapper .intro-input-underline {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 54px;
    left: 0;
    background-color: #23205f;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    will-change: transform;
    -webkit-transition: -webkit-transform 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 350ms cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 350ms cubic-bezier(0.645, 0.045, 0.355, 1); }
  .intro-input-wrapper .intro-input:focus ~ .intro-input-underline {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }
  .intro-input-wrapper .intro-input:not(:focus) ~ .pass-eye {
    display: none; }
  .intro-input-wrapper .intro-input:not(.has-text):not(:focus) {
    color: transparent; }
  .intro-input-wrapper .select-arrow {
    position: absolute;
    right: 22px;
    top: 26px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #939597; }
  .intro-input-wrapper .helper {
    margin-left: 16px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 1.71; }
  .intro-input-wrapper .pass-eye {
    position: absolute;
    width: 45px;
    height: 45px;
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    background: url(/static/media/pass-eye.23cead81.svg);
    background-position: center;
    background-repeat: no-repeat; }
    .intro-input-wrapper .pass-eye .eye-line {
      position: absolute;
      top: 21px;
      right: 9px;
      width: 25px;
      height: 2px;
      background-color: #8f8f8f;
      box-shadow: 0 2px 0 #f1f1f1;
      -webkit-transform: rotate(45deg) translateX(0) scaleX(1);
              transform: rotate(45deg) translateX(0) scaleX(1);
      -webkit-transition: -webkit-transform 200ms ease-in-out;
      transition: -webkit-transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out; }
      .intro-input-wrapper .pass-eye .eye-line.enabled {
        -webkit-transform: rotate(45deg) translateX(-50%) scaleX(0);
                transform: rotate(45deg) translateX(-50%) scaleX(0); }
  .intro-input-wrapper .location-pin,
  .intro-input-wrapper .date-icon {
    position: absolute;
    width: 45px;
    height: 45px;
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none; }
  .intro-input-wrapper .location-pin {
    background-image: url(/static/media/location-pin.fbd5188e.svg); }
  .intro-input-wrapper .date-icon {
    background-image: url(/static/media/date.eee7e256.svg); }
  .intro-input-wrapper .error {
    position: absolute;
    bottom: -17px;
    display: block;
    color: #b91d23;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    margin-left: 16px; }

@-webkit-keyframes errorFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes errorFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

.login-with-email {
  padding-top: 72px;
  padding-bottom: 33px; }
  .login-with-email .intro-input-wrapper {
    margin-bottom: 32px; }

.after-button-link {
  display: block;
  margin-top: 33px;
  text-align: center;
  color: #5e8aa8;
  font-size: 16px; }
  .after-button-link:hover, .after-button-link:focus {
    color: #e0383f; }

.sign-in-with-social-buttons {
  border-top: 1px solid #e9e8ef;
  padding-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .sign-in-with-social-buttons span {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    color: #23205f; }
  .sign-in-with-social-buttons .signin-with-social-buttons-right {
    display: flex; }
    .sign-in-with-social-buttons .signin-with-social-buttons-right button {
      display: flex;
      align-items: center;
      margin-left: 68px;
      font-size: 22px;
      letter-spacing: -0.2px;
      text-decoration: none;
      border: none;
      background: transparent;
      cursor: pointer; }
      .sign-in-with-social-buttons .signin-with-social-buttons-right button img {
        margin-right: 13px; }
      .sign-in-with-social-buttons .signin-with-social-buttons-right button.facebook-sign-in-link {
        color: #23205f; }
      .sign-in-with-social-buttons .signin-with-social-buttons-right button.linkedin-sign-in-link {
        color: #242a3a; }
    .sign-in-with-social-buttons .signin-with-social-buttons-right a:hover {
      color: #e0383f; }


.login-screen-header {
  height: 128px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between; }
  .login-screen-header h1 {
    font-size: 40px; }
  .login-screen-header .login-screen-right {
    font-size: 18px; }
    .login-screen-header .login-screen-right a {
      font-size: 16px;
      margin-left: 11px;
      color: #ffffff; }
    .login-screen-header .login-screen-right a:hover,
    .login-screen-header .login-screen-right a:focus {
      color: #e0383f; }

.forgot-password-form {
  padding-top: 72px; }
  .forgot-password-form button {
    margin-top: 32px; }

.four-digit-input {
  font-family: "Roboto Mono", monospace;
  font-size: 36px;
  color: #23205f;
  letter-spacing: 29px;
  width: 230px;
  padding-left: 20px;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none; }

.four-digit-indicator {
  width: 230px;
  display: flex;
  justify-content: space-between;
  padding: 0 29px 0 16px;
  margin-top: 13px; }
  .four-digit-indicator span {
    width: 30px;
    height: 4px;
    background-color: #e9e8ef; }

.four-digit-indicator.one span:nth-child(1) {
  background-color: #23205f; }

.four-digit-indicator.two span:nth-child(1),
.four-digit-indicator.two span:nth-child(2) {
  background-color: #23205f; }

.four-digit-indicator.three span:nth-child(1),
.four-digit-indicator.three span:nth-child(2),
.four-digit-indicator.three span:nth-child(3) {
  background-color: #23205f; }

.four-digit-indicator.four span:nth-child(1),
.four-digit-indicator.four span:nth-child(2),
.four-digit-indicator.four span:nth-child(3),
.four-digit-indicator.four span:nth-child(4) {
  background-color: #23205f; }

.forgot-password-form-step-2 {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px; }
  .forgot-password-form-step-2 button {
    margin-top: 32px; }
  .forgot-password-form-step-2 span {
    max-width: 290px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #939597; }
  .forgot-password-form-step-2 .email {
    margin-top: 16px;
    color: #404040;
    font-family: "Roboto", sans-serif;
    font-size: 24px; }
    .forgot-password-form-step-2 .email a {
      color: #404040;
      text-decoration: none;
      box-shadow: 0 2px 0 #4040402c; }
    .forgot-password-form-step-2 .email a:hover {
      box-shadow: 0 2px 0 #b91d232c;
      color: #e0383f; }
  .forgot-password-form-step-2 a {
    margin-top: 16px;
    color: #5e8aa8; }
  .forgot-password-form-step-2 a:hover {
    color: #e0383f; }
  .forgot-password-form-step-2 .four-digit-input-wrapper {
    margin-top: 24px;
    margin-left: 14px; }
  .forgot-password-form-step-2 .send-again {
    margin-top: 45px; }
    .forgot-password-form-step-2 .send-again button {
      margin-top: 16px;
      color: #5e8aa8;
      background: transparent;
      border: none;
      cursor: pointer; }
    .forgot-password-form-step-2 .send-again button:hover {
      box-shadow: 0 2px 0 #b91d232c;
      color: #e0383f; }

.forgot-password-form-step-3 {
  padding-top: 72px; }
  .forgot-password-form-step-3 button.red {
    margin-top: 32px; }

.forgot-pass-screen-header {
  height: 128px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center; }
  .forgot-pass-screen-header h1 {
    font-size: 40px; }

.steps-counter span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  opacity: 0.2;
  margin: 5px;
  border-radius: 50%; }
  .steps-counter span.active {
    opacity: 1; }

.checkbox-wrapper {
  min-height: 24px;
  display: flex;
  align-items: flex-start;
  color: #939597;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-right: 10px; }
  .checkbox-wrapper input {
    display: none; }
  .checkbox-wrapper input:checked ~ .custom-checkbox {
    box-shadow: inset 0 0 0 9px #23205f;
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }
    .checkbox-wrapper input:checked ~ .custom-checkbox .tick-left {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      -webkit-transition-delay: 150ms;
              transition-delay: 150ms; }
    .checkbox-wrapper input:checked ~ .custom-checkbox .tick-right {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      -webkit-transition-delay: 220ms;
              transition-delay: 220ms; }
  .checkbox-wrapper .label {
    -webkit-transition: color 200ms;
    transition: color 200ms; }
  .checkbox-wrapper input:checked ~ span {
    color: #23205f; }
  .checkbox-wrapper .custom-checkbox {
    width: 18px;
    height: 18px;
    box-shadow: inset 0 0 0 2px #d1d3d4;
    border-radius: 2px;
    margin-right: 12px;
    -webkit-transition: box-shadow 200ms ease;
    transition: box-shadow 200ms ease;
    -webkit-transition-delay: 190ms;
            transition-delay: 190ms;
    position: relative;
    flex-shrink: 0; }
    .checkbox-wrapper .custom-checkbox .tick-left {
      background-color: #ffffff;
      position: absolute;
      top: 9px;
      left: 4px;
      width: 5px;
      height: 2px;
      -webkit-transform: rotate(45deg) translateX(-50%) scaleX(0);
              transform: rotate(45deg) translateX(-50%) scaleX(0);
      -webkit-transition: -webkit-transform 70ms;
      transition: -webkit-transform 70ms;
      transition: transform 70ms;
      transition: transform 70ms, -webkit-transform 70ms;
      -webkit-transition-delay: 120ms;
              transition-delay: 120ms; }
    .checkbox-wrapper .custom-checkbox .tick-right {
      background-color: #ffffff;
      position: absolute;
      top: 8px;
      left: 6px;
      width: 10px;
      height: 2px;
      -webkit-transform: rotate(-45deg) translateX(-50%) scaleX(0);
              transform: rotate(-45deg) translateX(-50%) scaleX(0);
      -webkit-transition: -webkit-transform 120ms;
      transition: -webkit-transform 120ms;
      transition: transform 120ms;
      transition: transform 120ms, -webkit-transform 120ms;
      -webkit-transition-delay: 0ms;
              transition-delay: 0ms; }

.sign-up-form-header {
  background-color: #f1f1f2; }
  .sign-up-form-header .sign-in-with-social-buttons {
    padding-top: 22px;
    padding-bottom: 24px; }

.radio-input-wrapper {
  font-family: "Roboto", sans-serif;
  color: #23205f;
  margin-top: 0;
  margin-bottom: 8px;
  position: relative;
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */ }
  .radio-input-wrapper p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 34px; }
  .radio-input-wrapper .option-wrapper {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-right: 31px;
    margin-left: 16px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #23205f;
    /* Create a custom radio button */ }
    .radio-input-wrapper .option-wrapper input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
    .radio-input-wrapper .option-wrapper .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      border: 2px solid #d2d2d2;
      -webkit-transition: border 250ms;
      transition: border 250ms; }
  .radio-input-wrapper .option-wrapper:hover input ~ .checkmark {
    border: 2px solid #b91d23;
    -webkit-transition: border 100ms;
    transition: border 100ms; }
  .radio-input-wrapper .option-wrapper input:checked ~ .checkmark {
    border: 2px solid #b91d23; }
  .radio-input-wrapper .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: -webkit-transform 150ms;
    transition: -webkit-transform 150ms;
    transition: transform 150ms;
    transition: transform 150ms, -webkit-transform 150ms; }
  .radio-input-wrapper .option-wrapper input:checked ~ .checkmark:after {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .radio-input-wrapper .option-wrapper .checkmark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #b91d23; }
  .radio-input-wrapper .error {
    position: absolute;
    margin-left: 16px;
    bottom: -10px;
    display: block;
    color: #b91d23;
    font-family: "Roboto", sans-serif;
    font-size: 12px; }

@-webkit-keyframes errorFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes errorFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

.sign-up-step-1 {
  padding-top: 24px;
  padding-bottom: 80px; }
  .sign-up-step-1 .intro-input-wrapper {
    margin-top: 32px; }
  .sign-up-step-1 button.red {
    margin-top: 32px; }
  .sign-up-step-1 .already-have {
    font-size: 18px;
    color: #23205f;
    margin-top: 35px;
    display: block;
    text-align: center; }
    .sign-up-step-1 .already-have a {
      font-size: 16px;
      color: #5e8aa8;
      font-weight: 500; }
      .sign-up-step-1 .already-have a:hover {
        color: #e0383f; }

.accept {
  margin-top: 32px;
  align-items: center;
  position: relative; }
  .accept a {
    font-size: 16px;
    color: #5e8aa8;
    font-weight: 500; }
    .accept a:hover {
      color: #e0383f; }
  .accept .error {
    position: absolute;
    margin-left: 16px;
    bottom: -15px;
    display: block;
    font-weight: normal;
    color: #b91d23;
    font-family: "Roboto", sans-serif;
    font-size: 12px; }

@keyframes errorFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

.sign-up-form-step-2 {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px; }
  .sign-up-form-step-2 button {
    margin-top: 32px; }
  .sign-up-form-step-2 span {
    max-width: 290px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #939597; }
  .sign-up-form-step-2 .email {
    margin-top: 16px;
    color: #404040;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    text-decoration: none; }
    .sign-up-form-step-2 .email:hover {
      box-shadow: 0 2px 0 #b91d232c;
      color: #e0383f; }
  .sign-up-form-step-2 a {
    margin-top: 16px;
    color: #5e8aa8; }
  .sign-up-form-step-2 a:hover {
    color: #e0383f; }
  .sign-up-form-step-2 .change-email {
    margin-top: 16px;
    color: #5e8aa8;
    background: transparent;
    border: none;
    cursor: pointer; }
  .sign-up-form-step-2 .change-email:hover {
    color: #e0383f; }
  .sign-up-form-step-2 .four-digit-input-wrapper {
    margin-top: 24px;
    margin-left: 14px; }
  .sign-up-form-step-2 .send-again {
    margin-top: 45px; }
  .sign-up-form-step-2 .error {
    color: #b91d23;
    margin-top: 10px; }

.sign-up-form-step-3 {
  padding-top: 72px; }
  .sign-up-form-step-3 button.red {
    margin-top: 32px; }

.sign-up-avatar {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden; }
  .sign-up-avatar .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/static/media/avatar-placeholder.bc087377.svg), #d2d2d2;
    background-position: center;
    background-repeat: no-repeat; }
  .sign-up-avatar .uploaded-avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .sign-up-avatar .uploaded-avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .sign-up-avatar .upload-progressbar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    mix-blend-mode: multiply;
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.sign-up-avatar-uploader {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6px; }

.avatar-upload-button {
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #23205f;
  padding: 10px 37px 12px;
  height: 40px;
  border-radius: 41px;
  border: solid 2px #23205f;
  line-height: 1;
  display: flex;
  margin-top: 31px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }
  .avatar-upload-button svg {
    width: 15px;
    margin-right: 10px; }
    .avatar-upload-button svg .plus {
      fill: #23205f; }
  .avatar-upload-button:hover {
    background-color: #23205f;
    color: white;
    box-shadow: 0 7px 7px #23205f33; }
    .avatar-upload-button:hover svg .plus {
      fill: #ffffff; }

.sign-up-form-step-4 {
  padding-top: 32px;
  padding-bottom: 80px; }
  .sign-up-form-step-4 button.red {
    margin-top: 32px; }
  .sign-up-form-step-4 .intro-input-wrapper {
    margin-top: 32px; }

.intro-slider-wrapper {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  width: 100%;
  color: #23205f;
  margin-top: 47px;
  font-family: "Roboto", sans-serif; }
  .intro-slider-wrapper [type="range"] {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 68px;
    padding-bottom: 20px;
    background: transparent; }
    .intro-slider-wrapper [type="range"], .intro-slider-wrapper [type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none; }
    .intro-slider-wrapper [type="range"]::-webkit-slider-runnable-track {
      box-sizing: border-box;
      border: none;
      width: 100%;
      height: 12px;
      border-radius: 6px;
      background: -webkit-gradient(linear, left top, right top, from(#23205f), to(#d1d3d44d));
      background: -webkit-linear-gradient(left, #23205f calc(calc(3 - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(3 - 1) / 4 * 100% + 0.5%));
      background: linear-gradient(90deg, #23205f calc(calc(3 - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(3 - 1) / 4 * 100% + 0.5%));
      background: -webkit-linear-gradient(left, #23205f calc(calc(var(--val, 3) - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(var(--val, 3) - 1) / 4 * 100% + 0.5%));
      background: linear-gradient(90deg, #23205f calc(calc(var(--val, 3) - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(var(--val, 3) - 1) / 4 * 100% + 0.5%)); }
    .intro-slider-wrapper [type="range"]::-moz-range-track {
      box-sizing: border-box;
      border: none;
      width: 100%;
      height: 12px;
      border-radius: 6px;
      background: linear-gradient(90deg, #23205f calc(calc(3 - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(3 - 1) / 4 * 100% + 0.5%));
      background: linear-gradient(90deg, #23205f calc(calc(var(--val, 3) - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(var(--val, 3) - 1) / 4 * 100% + 0.5%)); }
    .intro-slider-wrapper [type="range"]::-ms-track {
      box-sizing: border-box;
      border: none;
      width: 100%;
      height: 12px;
      border-radius: 6px;
      background: linear-gradient(90deg, #23205f calc(calc(3 - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(3 - 1) / 4 * 100% + 0.5%));
      background: linear-gradient(90deg, #23205f calc(calc(var(--val, 3) - 1) / 4 * 100% - 0.5%), #d1d3d44d calc(calc(var(--val, 3) - 1) / 4 * 100% + 0.5%)); }
    .intro-slider-wrapper [type="range"]::-moz-range-progress {
      height: 12px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background: #23205f; }
    .intro-slider-wrapper [type="range"]::-ms-fill-lower {
      height: 12px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background: #23205f; }
    .intro-slider-wrapper [type="range"]::-webkit-slider-thumb {
      margin-top: -6px;
      box-sizing: border-box;
      border: none;
      width: 12px;
      height: 24px;
      border-radius: 6px;
      background: #23205f; }
    .intro-slider-wrapper [type="range"]::-moz-range-thumb {
      box-sizing: border-box;
      border: none;
      width: 12px;
      height: 24px;
      border-radius: 6px;
      background: #23205f; }
    .intro-slider-wrapper [type="range"]::-ms-thumb {
      margin-top: 0;
      box-sizing: border-box;
      border: none;
      width: 12px;
      height: 24px;
      border-radius: 6px;
      background: #23205f; }
    .intro-slider-wrapper [type="range"]::-ms-tooltip {
      display: none; }
  .intro-slider-wrapper .text {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px; }
  .intro-slider-wrapper .labels {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    padding-left: 2px;
    padding-right: 1px; }
  .intro-slider-wrapper .dots {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 61px;
    left: 0;
    padding-left: 3px;
    padding-right: 3px;
    z-index: -1; }
    .intro-slider-wrapper .dots span {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #d1d3d4; }

.sign-up-form-step-5 {
  padding-top: 32px;
  padding-bottom: 80px; }
  .sign-up-form-step-5 .medium-sized-wrapper {
    padding: 0 20px;
    max-width: 616px; }
  .sign-up-form-step-5 h3 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.33;
    color: #23205f;
    margin-bottom: -20px;
    margin-top: 32px; }
  .sign-up-form-step-5 button.red {
    margin-top: 32px; }
  .sign-up-form-step-5 .intro-input-wrapper {
    margin-top: 32px; }
  .sign-up-form-step-5 .checkboxes-section {
    padding: 32px 20px 15px; }
    .sign-up-form-step-5 .checkboxes-section.specialisms {
      margin-top: 41px;
      background-color: #d1d3d431; }
    .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 864px;
      margin: 0 auto; }
      .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group {
        width: 33%;
        border-top: 1px solid #d1d3d4;
        margin-top: 32px; }
        .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group:nth-child(1), .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group:nth-child(2), .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group:nth-child(3) {
          margin-top: 0;
          border-top: none; }
          .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group:nth-child(1) h4, .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group:nth-child(2) h4, .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group:nth-child(3) h4 {
            margin-top: 0; }
        @media screen and (max-width: 678px) {
          .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group {
            width: 50%; } }
        @media screen and (max-width: 440px) {
          .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group {
            width: 100%; } }
        .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group h4 {
          margin-top: 23px;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-weight: 500; }
        .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group .checkboxes-group {
          flex-direction: column; }
          .sign-up-form-step-5 .checkboxes-section.skills .skills-wrapper .skills-group .checkboxes-group .checkbox-wrapper {
            width: 100%; }
    .sign-up-form-step-5 .checkboxes-section h2 {
      font-size: 24px;
      max-width: 866px;
      margin: 0 auto;
      margin-bottom: 24px; }
    .sign-up-form-step-5 .checkboxes-section .checkboxes-group {
      max-width: 864px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto; }
      .sign-up-form-step-5 .checkboxes-section .checkboxes-group .checkbox-wrapper {
        width: 33%;
        margin-bottom: 17px; }
        @media screen and (max-width: 678px) {
          .sign-up-form-step-5 .checkboxes-section .checkboxes-group .checkbox-wrapper {
            width: 50%; } }
        @media screen and (max-width: 440px) {
          .sign-up-form-step-5 .checkboxes-section .checkboxes-group .checkbox-wrapper {
            width: 100%; } }

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center; }
  .modal .modal-wrapper {
    background-color: #fefefe;
    margin: auto;
    padding: 8px;
    border: 1px solid #888;
    width: 672px;
    min-height: 396px;
    border-radius: 4px;
    position: relative; }
    .modal .modal-wrapper .modal-inner {
      display: flex;
      flex-direction: column;
      padding: 24px 40px 40px 40px; }
      .modal .modal-wrapper .modal-inner h3 {
        font-size: 40px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: center;
        color: #23205f;
        margin-bottom: 32px;
        align-self: flex-start;
        margin-top: 0; }
      .modal .modal-wrapper .modal-inner .modal-send-button {
        align-self: flex-end;
        font-size: 20px;
        line-height: 1.2;
        height: 48px; }
    .modal .modal-wrapper .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      align-self: flex-end; }
    .modal .modal-wrapper .close::before {
      content: '';
      width: 32px;
      height: 32px;
      background-image: url(/static/media/close-icon.d2ef42fb.svg);
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      position: absolute;
      top: 8px;
      right: 8px; }
    .modal .modal-wrapper .close:hover,
    .modal .modal-wrapper .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer; }

@media screen and (max-width: 767px) {
  .modal .modal-wrapper {
    width: 80vw; } }

@media screen and (max-width: 650px) {
  .modal .raise-query-form {
    width: 100%; }
    .modal .raise-query-form .modal-wrapper {
      width: 100%;
      border: none;
      border-radius: 0px; }
      .modal .raise-query-form .modal-wrapper .modal-inner {
        padding: 48px 16px 30px 16px; }
        .modal .raise-query-form .modal-wrapper .modal-inner h3 {
          margin-bottom: 16px; }
      .modal .raise-query-form .modal-wrapper .close::before {
        top: 16px;
        right: 16px; } }

@media screen and (max-width: 425px) {
  .modal .raise-query-form .modal-wrapper .modal-inner h3 {
    font-size: 28px;
    line-height: 1.43; } }

@font-face {
  font-family: "Roboto-Regular";
  src: url(/static/media/Roboto-Regular.11eabca2.ttf) format("truetype"); }

.sign-up-form-step-6 {
  width: 100%; }
  .sign-up-form-step-6 .terms-and-conditions-main {
    background-color: #fafafa;
    width: 100%;
    margin-bottom: 24px; }
    .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper {
      padding-bottom: 65px;
      max-width: 672px;
      margin: 0 auto;
      padding-top: 72px; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper p {
        font-family: 'Roboto-Regular', Roboto, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        line-height: 1.5;
        color: #414042; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper h3 {
        font-family: 'Roboto-Regular', Roboto, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #23205f;
        margin-top: 0;
        margin-bottom: 20px; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper h3.h3-1 {
        margin-top: 0;
        margin-bottom: 12px; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper p:nth-child(2) {
        margin-top: 0;
        margin-bottom: 0; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper h3.h3-2 {
        margin-top: 40px; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper h3.h3-4 {
        margin-top: 40px; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper h3.h3-3 {
        margin-top: 16px; }
  .sign-up-form-step-6 .terms-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 672px;
    margin: 0 auto; }
    .sign-up-form-step-6 .terms-buttons a {
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #23205f; }
      .sign-up-form-step-6 .terms-buttons a .download-link {
        display: flex;
        align-items: center;
        flex-direction: row; }
        .sign-up-form-step-6 .terms-buttons a .download-link .icon-background {
          width: 40px;
          height: 40px;
          background-color: #23205f;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 24px; }
          .sign-up-form-step-6 .terms-buttons a .download-link .icon-background .icon {
            background-image: url(/static/media/download.9e353eb4.svg);
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: inline-block;
            background-position: center; }
    .sign-up-form-step-6 .terms-buttons .button {
      font-weight: bold;
      line-height: 1.2; }
      .sign-up-form-step-6 .terms-buttons .button.gray {
        width: 168px;
        margin-right: 23px; }
      .sign-up-form-step-6 .terms-buttons .button.red.toggle-modal {
        width: 168px; }
  .sign-up-form-step-6 .modal .modal-wrapper .modal-inner .modal-content {
    position: relative; }
    .sign-up-form-step-6 .modal .modal-wrapper .modal-inner .modal-content label {
      font-family: 'Roboto-Regular', Roboto;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #5e8aa8;
      position: absolute;
      top: 8px;
      left: 17px; }
    .sign-up-form-step-6 .modal .modal-wrapper .modal-inner .modal-content textarea {
      width: 100%;
      height: 154px;
      border-radius: 4px 4px 0 0;
      margin-bottom: 24px;
      border: none;
      border-bottom: solid 2px #5e8aa8;
      background-color: rgba(209, 211, 212, 0.3);
      max-width: 100%;
      resize: none;
      font-family: 'Roboto-Regular', Roboto;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #242a3a;
      border-top: 27px solid transparent;
      border-bottom: 17px solid transparent;
      border-right: 17px solid transparent;
      border-left: 17px solid transparent; }
  .sign-up-form-step-6 .modal .modal-wrapper .modal-inner .modal-send-button {
    width: 198px;
    min-width: 168px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 22px;
    padding-right: 21px; }

@media screen and (max-width: 1020px) {
  .sign-up-form-step-6 .link-text {
    font-size: 14px; } }

@media screen and (max-width: 767px) {
  .large-sized-wrapper.intro-header-wrapper {
    text-align: center; }
  .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper {
    max-width: 85vw; }
  .sign-up-form-step-6 .terms-buttons {
    max-width: 85vw; } }

@media screen and (max-width: 680px) {
  .sign-up-form-step-6 .terms-buttons a {
    margin-right: 22px; }
    .sign-up-form-step-6 .terms-buttons a .download-link .link-text {
      display: none; }
    .sign-up-form-step-6 .terms-buttons a .download-link .icon-background {
      margin-right: 0px; }
  .sign-up-form-step-6 .terms-buttons .accept-and-query {
    width: 100%;
    justify-content: flex-end;
    display: flex; } }

@media screen and (max-width: 540px) {
  .sign-up-form-step-6 .terms-buttons .button.gray {
    width: 130px;
    padding: 0; }
  .sign-up-form-step-6 .terms-buttons .button.red.toggle-modal {
    width: 160px;
    padding: 0; } }

@media screen and (max-width: 425px) {
  .large-sized-wrapper.intro-header-wrapper h1 {
    font-size: 18px;
    line-height: 1.17; }
  .sign-up-form-step-6 .terms-and-conditions-main {
    margin-bottom: 32px; }
    .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper {
      padding: 32px 24px 24px 24px;
      max-width: 100%; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper h3 {
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        margin-bottom: 12px;
        margin-top: 32px; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper p {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71; }
      .sign-up-form-step-6 .terms-and-conditions-main .terms-and-conditions-text-wrapper h3.h3-1 {
        margin-top: 0; }
  .sign-up-form-step-6 .terms-buttons {
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px; }
    .sign-up-form-step-6 .terms-buttons .accept-and-query {
      width: 100%;
      display: flex;
      flex-wrap: nowrap; }
      .sign-up-form-step-6 .terms-buttons .accept-and-query .button.gray {
        width: 100%;
        padding: 0; }
      .sign-up-form-step-6 .terms-buttons .accept-and-query .button.red.toggle-modal {
        width: 145.2%;
        padding: 0; } }

.sign-up-screen-header {
  min-height: 128px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .sign-up-screen-header h1 {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 0; }
  .sign-up-screen-header h1:only-child {
    margin-bottom: 5px; }
  .sign-up-screen-header .steps-counter {
    margin-top: 10px; }

.sign-up-form-step-final {
  padding-top: 120px;
  padding-bottom: 80px;
  background-image: url(/static/media/x-for-complete.8c766716.svg), -webkit-gradient(linear, left top, left bottom, from(#23205f), to(#191919));
  background-image: url(/static/media/x-for-complete.8c766716.svg), -webkit-linear-gradient(top, #23205f, #191919);
  background-image: url(/static/media/x-for-complete.8c766716.svg), linear-gradient(to bottom, #23205f, #191919);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 40%, 100%;
  height: 100%; }
  .sign-up-form-step-final .medium-sized-wrapper {
    padding: 0 20px;
    max-width: 616px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff; }
    .sign-up-form-step-final .medium-sized-wrapper .tick-wrapper {
      width: 96px;
      height: 96px;
      position: relative; }
      .sign-up-form-step-final .medium-sized-wrapper .tick-wrapper .tick-outer-left {
        position: absolute;
        top: 48px;
        left: 2px;
        width: 40px;
        height: 20px;
        background-color: #ffffff;
        -webkit-transform: rotate(45deg) translateX(0) scaleX(1);
                transform: rotate(45deg) translateX(0) scaleX(1);
        -webkit-animation: tickPartAnimationLeft 600ms 1000ms backwards ease-in;
                animation: tickPartAnimationLeft 600ms 1000ms backwards ease-in; }
      .sign-up-form-step-final .medium-sized-wrapper .tick-wrapper .tick-outer-right {
        position: absolute;
        top: 40px;
        left: 17px;
        width: 85px;
        height: 20px;
        background-color: #ffffff;
        -webkit-transform: rotate(315deg) translateX(0) scaleX(1);
                transform: rotate(315deg) translateX(0) scaleX(1);
        -webkit-animation: tickPartAnimationRight 600ms 1570ms backwards ease-out;
                animation: tickPartAnimationRight 600ms 1570ms backwards ease-out; }
      .sign-up-form-step-final .medium-sized-wrapper .tick-wrapper .tick-inner-left {
        position: absolute;
        top: 58px;
        left: 6px;
        width: 39px;
        height: 7px;
        background-color: #201e50;
        -webkit-transform: rotate(45deg) translateX(0) scaleX(1);
                transform: rotate(45deg) translateX(0) scaleX(1);
        -webkit-animation: tickPartAnimationLeft 600ms 1000ms backwards ease-in;
                animation: tickPartAnimationLeft 600ms 1000ms backwards ease-in; }
      .sign-up-form-step-final .medium-sized-wrapper .tick-wrapper .tick-inner-right {
        position: absolute;
        top: 45px;
        left: 28px;
        width: 66px;
        height: 7px;
        background-color: #201e50;
        -webkit-transform: rotate(315deg) translateX(0) scaleX(1);
                transform: rotate(315deg) translateX(0) scaleX(1);
        -webkit-animation: tickPartAnimationRight 600ms 1570ms backwards ease-out;
                animation: tickPartAnimationRight 600ms 1570ms backwards ease-out; }
    .sign-up-form-step-final .medium-sized-wrapper h1 {
      margin-top: 24px;
      margin-bottom: 0;
      font-size: 40px;
      text-align: center; }
    .sign-up-form-step-final .medium-sized-wrapper p {
      text-align: center;
      margin-top: 23px;
      max-width: 250px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 1.5; }
    .sign-up-form-step-final .medium-sized-wrapper .button {
      margin-top: 41px;
      padding-left: 70px;
      padding-right: 70px; }

@-webkit-keyframes tickPartAnimationLeft {
  0% {
    -webkit-transform: rotate(45deg) translateX(-50%) scaleX(0);
            transform: rotate(45deg) translateX(-50%) scaleX(0); }
  100% {
    -webkit-transform: rotate(45deg) translateX(0) scaleX(1);
            transform: rotate(45deg) translateX(0) scaleX(1); } }

@keyframes tickPartAnimationLeft {
  0% {
    -webkit-transform: rotate(45deg) translateX(-50%) scaleX(0);
            transform: rotate(45deg) translateX(-50%) scaleX(0); }
  100% {
    -webkit-transform: rotate(45deg) translateX(0) scaleX(1);
            transform: rotate(45deg) translateX(0) scaleX(1); } }

@-webkit-keyframes tickPartAnimationRight {
  0% {
    -webkit-transform: rotate(315deg) translateX(-50%) scaleX(0);
            transform: rotate(315deg) translateX(-50%) scaleX(0); }
  100% {
    -webkit-transform: rotate(315deg) translateX(0) scaleX(1);
            transform: rotate(315deg) translateX(0) scaleX(1); } }

@keyframes tickPartAnimationRight {
  0% {
    -webkit-transform: rotate(315deg) translateX(-50%) scaleX(0);
            transform: rotate(315deg) translateX(-50%) scaleX(0); }
  100% {
    -webkit-transform: rotate(315deg) translateX(0) scaleX(1);
            transform: rotate(315deg) translateX(0) scaleX(1); } }

.s-base-search {
  width: 100%;
  padding: 0 20px;
  min-height: 266px;
  background-image: url(/static/media/base-search-bg.1cb36154.jpg);
  background-size: cover;
  background-position: center; }
  .s-base-search .search-wrapper {
    max-width: 746px;
    height: 266px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .s-base-search .search-wrapper h1 {
      margin-top: 0;
      margin-bottom: 32px;
      color: white;
      font-size: 40px; }
    .s-base-search .search-wrapper .search-form-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 20px;
      font-family: "Roboto", sans-serif; }
      .s-base-search .search-wrapper .search-form-wrapper input {
        height: 56px;
        border-radius: 4px;
        border: none;
        width: unset;
        min-width: 0;
        flex-grow: 6;
        padding: 0 20px; }
        .s-base-search .search-wrapper .search-form-wrapper input:placeholder {
          color: #989898; }
      .s-base-search .search-wrapper .search-form-wrapper button {
        margin-left: 24px;
        flex-grow: 1; }

.s-tag-bar {
  overflow-x: scroll; }
  .s-tag-bar .tag-bar {
    display: flex;
    padding: 16px 24px 12px; }
    .s-tag-bar .tag-bar button {
      flex-shrink: 0;
      margin-left: 16px; }
    .s-tag-bar .tag-bar button:first-child {
      margin-left: 0; }
    .s-tag-bar .tag-bar .spacer {
      flex-basis: 24px;
      flex-shrink: 0; }
  .s-tag-bar.after-post {
    overflow: auto;
    flex-wrap: wrap; }
    .s-tag-bar.after-post .tag-bar {
      padding-left: 0; }
      .s-tag-bar.after-post .tag-bar button {
        margin-bottom: 10px; }

.s-big-card {
  display: flex;
  width: 100%; }
  .s-big-card .photo {
    flex-basis: 48%;
    flex-shrink: 0;
    height: 100%; }
    .s-big-card .photo img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      align-self: stretch; }
  .s-big-card .right-column {
    background-color: #ffffff;
    padding: 37px 56px 44px 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .s-big-card .right-column .sub-heading {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #5e8aa8;
      margin-bottom: 0; }
    .s-big-card .right-column .heading {
      margin-top: 16px;
      font-family: "Roboto", sans-serif;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 0; }
    .s-big-card .right-column .text {
      color: #414042;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 1.75;
      margin-top: 24px;
      margin-bottom: 40px; }

.small-card {
  display: flex;
  flex-direction: column;
  width: calc(33% - 12px);
  margin-top: 24px;
  text-decoration: none;
  color: unset;
  -webkit-transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform, box-shadow; }
  .small-card:hover {
    box-shadow: 0 20px 30px -10px #000000aa;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  .small-card .top-text {
    background-color: #ffffff;
    padding: 22px 24px 24px 24px; }
    .small-card .top-text .sub-heading {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #5e8aa8;
      margin-top: 0; }
    .small-card .top-text .heading {
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      font-weight: bold;
      margin-top: 7px;
      margin-bottom: 0; }
    .small-card .top-text .text {
      color: #414042;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 1.5;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 24px;
      margin-bottom: 0; }
  .small-card .photo {
    height: 200px;
    width: 100%;
    object-fit: cover;
    align-self: stretch; }

.base-wrapper {
  padding: 24px;
  background-color: #e9e8ef;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap; }
  .breadcrumbs .crumb {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    padding: 11px 16px;
    background-color: #e9e8ef;
    margin-left: 13px;
    margin-right: 10px;
    position: relative;
    height: 40px;
    text-decoration: none;
    color: unset;
    margin-bottom: 10px; }
    .breadcrumbs .crumb:hover {
      text-decoration: underline; }
    .breadcrumbs .crumb::before {
      content: "";
      position: absolute;
      top: 0;
      left: -9px;
      width: 10px;
      height: 40px;
      background-image: url(/static/media/crumb-start.2bcc0a20.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat; }
    .breadcrumbs .crumb::after {
      content: "";
      position: absolute;
      top: 0;
      right: -9px;
      width: 10px;
      height: 100%;
      background-image: url(/static/media/crumb-end.bf9688e4.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat; }
    .breadcrumbs .crumb:first-child {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
      .breadcrumbs .crumb:first-child::before {
        display: none; }
    .breadcrumbs .crumb:last-child {
      margin-right: 0;
      background-color: transparent;
      border-top: 2px solid #e5e5e5;
      border-bottom: 2px solid #e5e5e5;
      border-right: 2px solid #e5e5e5;
      padding-top: 9px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
      .breadcrumbs .crumb:last-child::before {
        top: -2px;
        background-image: url(/static/media/last-crumb-start.6295e737.svg); }
      .breadcrumbs .crumb:last-child::after {
        display: none; }

.author-card {
  display: flex;
  margin-top: 34px;
  justify-content: space-between; }
  .author-card .left-wrapper {
    display: flex; }
    .author-card .left-wrapper .avatar {
      position: relative;
      width: 72px;
      height: 72px; }
      .author-card .left-wrapper .avatar img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; }
      .author-card .left-wrapper .avatar .onlineness-dot {
        position: absolute;
        right: 3px;
        bottom: 3px;
        width: 14px;
        height: 14px;
        background-color: #4dd760;
        border-radius: 50%;
        border: 2px solid #ffffff; }
    .author-card .left-wrapper .info {
      margin-left: 24px; }
      .author-card .left-wrapper .info .name {
        font-family: TitilliumWeb, sans-serif;
        font-size: 24px;
        line-height: 1.33;
        color: #000000;
        margin-top: 7px;
        margin-bottom: 0; }
      .author-card .left-wrapper .info .location {
        padding-left: 20px;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        margin-top: 4px;
        color: #a5a5b1;
        background-image: url(/static/media/location-pin.fbd5188e.svg);
        background-size: contain;
        background-position: left center;
        background-repeat: no-repeat; }
    .author-card .left-wrapper.after-post {
      margin-top: 0;
      border-top: 2px solid #dadada;
      padding-top: 31px; }
  .author-card .like-section {
    display: flex;
    align-items: center; }
    .author-card .like-section span {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.5;
      color: #23205f; }
    .author-card .like-section .like {
      margin-left: 32px; }

.post-content {
  font-family: "Roboto", sans-serif;
  margin-top: 59px; }
  .post-content h2 {
    font-size: 32px;
    line-height: 1.25;
    color: #23205f;
    font-weight: bold; }
  .post-content h3,
  .post-content h4,
  .post-content h5 {
    font-size: 16px;
    line-height: 1.75;
    font-weight: bold; }
  .post-content p {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.75;
    color: #414042; }
  .post-content br + br {
    display: none; }
  .post-content img {
    max-width: 100%; }

.cover-pic {
  width: 100%;
  height: 32vh;
  object-fit: cover; }

.s-post {
  padding: 16px 20px 49px; }
  .s-post .post-wrapper {
    max-width: 745px;
    margin: 0 auto; }
  .s-post h1 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    color: #23205f;
    margin-top: 55px;
    margin-bottom: 0; }
  .s-post .intro-text {
    padding-bottom: 32px;
    border-bottom: 1px solid #e9e8ef;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.75;
    color: #414042; }

.s-more-from-base {
  background-color: #e9e8ef;
  padding: 24px; }
  .s-more-from-base > h2 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-align: center; }
  .s-more-from-base .more-from-base-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

.medium-sized-wrapper {
  width: 100%;
  max-width: 576px;
  margin: 0 auto; }

.s-intro-form {
  padding: 0 20px; }

.intro-screen-wrapper {
  padding-bottom: 80px; }

.fade-enter {
  opacity: 0.01; }

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms 300ms ease-in;
  transition: opacity 300ms 300ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  #root .header {
    flex-grow: 0; }
  #root .routes-wrapper {
    position: relative;
    flex-grow: 1; }
    #root .routes-wrapper .one-route {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }

