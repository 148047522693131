.post-content {
  font-family: "Roboto", sans-serif;
  margin-top: 59px;
  h2 {
    font-size: 32px;
    line-height: 1.25;
    color: #23205f;
    font-weight: bold;
  }
  h3,
  h4,
  h5 {
    font-size: 16px;
    line-height: 1.75;
    font-weight: bold;
  }
  p {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.75;
    color: #414042;
  }

  br + br {
    display: none;
  }

  img {
    max-width: 100%;
  }
}
