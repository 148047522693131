.breadcrumbs {
  display: flex;
  flex-wrap: wrap;

  .crumb {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    padding: 11px 16px;
    background-color: #e9e8ef;
    margin-left: 13px;
    margin-right: 10px;
    position: relative;
    height: 40px;
    text-decoration: none;
    color: unset;
    margin-bottom: 10px;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -9px;
      width: 10px;
      height: 40px;
      background-image: url("../../assets/svg/crumb-start.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -9px;
      width: 10px;
      height: 100%;
      background-image: url("../../assets/svg/crumb-end.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &:first-child {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &::before {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
      background-color: transparent;
      border-top: 2px solid #e5e5e5;
      border-bottom: 2px solid #e5e5e5;
      border-right: 2px solid #e5e5e5;

      padding-top: 9px;

      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &::before {
        top: -2px;
        background-image: url("../../assets/svg/last-crumb-start.svg");
      }
      &::after {
        display: none;
      }
    }
  }
}
