.login-with-email {
  padding-top: 72px;

  .intro-input-wrapper {
    margin-bottom: 32px;
  }

  padding-bottom: 33px;
}
.after-button-link {
  display: block;
  margin-top: 33px;
  text-align: center;
  color: #5e8aa8;
  font-size: 16px;

  &:hover,
  &:focus {
    color: lighten(#b91d23, 13%);
  }
}
