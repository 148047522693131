.steps-counter {
  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    opacity: 0.2;
    margin: 5px;
    border-radius: 50%;

    &.active {
      opacity: 1;
    }
  }
}
